import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Paragraph from "../components/Paragraph";
import ContentGenerator from "../components/ContentGenerator";
import StoryDetails from "../components/StoryDetails";
import StoryTitle from "../components/StoryTitle";
import { useStory } from "../context/StoryContext";
import { getRandomPremise } from "../utils/premiseProvider";
import { getNewParagraphs, getSectionSummary } from "../api/paragraphs";
import { generateTitle, getSavedStoryById } from "../api/stories";
import {
  FiRefreshCw,
  FiChevronDown,
  FiChevronUp,
  FiList,
  FiLoader,
  FiChevronsDown,
  FiTarget,
} from "react-icons/fi";
import { BsInfinity } from "react-icons/bs";
import {
  saveStoryToLocalStorage,
  loadLastStoryFromLocalStorage,
  loadStoryFromTitle,
  loadStoryFromId,
} from "../utils/storageUtils";
import StoryActionButtons from "../components/StoryActionButtons";
import SectionBreak from "../components/SectionBreak";
import StoryProgressBar from "../components/StoryProgressBar";
import { getContextForMode } from "../utils/contextUtils";
import { getStoryGeneratorHelmet } from "../utils/helmetUtils";
import PickedSentencesList from "../components/PickedSentencesList";

const isMobile = () => {
  return window.innerWidth < 640; // 640px is the 'sm' breakpoint in Tailwind
};

const StoryGeneratorFull = ({ darkMode = false, isNsfw = false }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeParagraph, setActiveParagraph] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [title, setTitle] = useState("AI Story Generator");
  const { state, dispatch } = useStory();
  const [showSectionBreakButtons, setShowSectionBreakButtons] = useState(false);
  const [showSummarySentencesButtons, setShowSummarySentencesButtons] =
    useState(false);
  const [isContinueStory, setIsContinueStory] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClickable, setIsClickable] = useState(true);
  const [isDrafting, setIsDrafting] = useState(false);
  const [draftParagraphIndex, setDraftParagraphIndex] = useState(null);

  const storyContainerRef = useRef(null);

  const initialTargetPercentage = 10;
  const initialParagraphs = 3;

  const helmetData = getStoryGeneratorHelmet(isNsfw);

  useEffect(() => {
    setTitle("AI Story Generator");
    const searchParams = new URLSearchParams(location.search);
    const isNew = searchParams.get("new") === "true";
    // console.log("location.state", location.state);
    if (location.state?.fromSlimVersion && state.premise === "") {
      loadFromLocalStorage();
      return;
    }
    if (location.state && location.state.story_id) {
      if (location.state.from_saved) {
        console.log("Loading story from saved id");
        loadFromSavedId(location.state.story_id);
      } else {
        console.log("Loading story from local storage id");
        loadFromLocalStorageId(location.state.story_id);
      }
      // loadFromTitle(location.state.story_title);
    } else if (location.state && location.state.premise) {
      console.log("Setting premise from location state");
      dispatch({ type: "SET_PREMISE", payload: location.state.premise });
      dispatch({ type: "SET_NSFW", payload: isNsfw });
    } else if (location.state && location.state.fromSlimVersion) {
      console.log("state already loaded ", state);
      scrollToBottom();
    } else if (isNew) {
      resetStory();
    } else {
      loadFromLocalStorage();
    }
  }, [location]);

  useEffect(() => {
    if (state.paragraphs.length > 0 && state.title) {
      console.log("Saving story to local storage");
      saveStoryToLocalStorage({
        title: state.title,
        premise: state.premise,
        genre: state.genre,
        isNsfw: state.isNsfw,
        paragraphs: state.paragraphs,
        storyProgress: state.storyProgress,
        generationMode: state.generationMode,
        overall_summary: state.overall_summary,
        pickedSentences: state.pickedSentences,
      });
    }
    if (state.title) {
      setTitle(state.title);
    } else {
      setTitle("AI Story Generator");
    }
  }, [
    activeParagraph,
    state.paragraphs,
    state.pickedSentences,
    state.premise,
    state.title,
    state.generationMode,
    state.overall_summary,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 10);
    };

    // Initial check
    handleScroll();
    localStorage.removeItem("storyGeneratorPreferences");

    // Add event listener
    window.addEventListener("scroll", handleScroll, { passive: true });

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.state?.fromCompactView) {
      const { generatedContent, mode, paragraphContext } = location.state;
      handleContentFinalize(generatedContent, mode, paragraphContext);
    }
  }, [location.state]);

  const loadStory = (storyData) => {
    if (storyData && storyData.paragraphs) {
      console.log("Loading story:", storyData.id);
      dispatch({ type: "SET_TITLE", payload: storyData.title });
      dispatch({ type: "SET_PREMISE", payload: storyData.premise });
      dispatch({ type: "SET_GENRE", payload: storyData.genre });
      dispatch({ type: "SET_PARAGRAPHS", payload: storyData.paragraphs });
      dispatch({
        type: "SET_PICKED_SENTENCES",
        payload: storyData.pickedSentences || {},
      });
      dispatch({ type: "SET_NSFW", payload: storyData.isNsfw || false });
      dispatch({
        type: "SET_OVERALL_SUMMARY",
        payload: storyData.overall_summary,
      });

      if (storyData.storyProgress) {
        dispatch({
          type: "UPDATE_STORY_PROGRESS",
          payload: { percentage: storyData.storyProgress },
        });
      }

      dispatch({
        type: "SET_GENERATION_MODE",
        payload: storyData.generationMode || "open_ended",
      });
      return true;
    }
    return false;
  };

  const loadFromLocalStorage = () => {
    const lastStory = loadLastStoryFromLocalStorage(isNsfw);
    if (!loadStory(lastStory)) {
      generateNewPremise(isNsfw);
    }
  };

  const loadFromTitle = (title) => {
    const story = loadStoryFromTitle(title);
    if (story) {
      loadStory(story);
    }
  };

  const loadFromSavedId = async (id) => {
    console.log("Loading story from id:", id);
    const story = await getSavedStoryById(id);
    if (story) {
      loadStory(story);
    } else {
      console.log(
        "Story not found in database, trying to load from local storage"
      );
      const story = loadStoryFromId(id);
      if (story) {
        loadStory(story);
      }
    }
  };

  const loadFromLocalStorageId = (id) => {
    const story = loadStoryFromId(id);
    if (story) {
      loadStory(story);
    }
  };

  const generateNewPremise = () => {
    console.log("Generating new premise with isNsfw:", isNsfw);
    const premiseData = getRandomPremise(isNsfw);
    dispatch({ type: "SET_PREMISE", payload: premiseData.premise });
    dispatch({ type: "SET_GENRE", payload: premiseData.genre });
    dispatch({ type: "SET_NSFW", payload: isNsfw });
  };

  const onGenerateProgress = (progress) => {
    dispatch({ type: "SET_PARAGRAPHS", payload: progress });
    if (state.generationMode === "percentage_based") {
      const progressPercentage =
        (progress.length / initialParagraphs) * initialTargetPercentage;
      const newPercentage = Math.floor(
        Math.min(progressPercentage, initialTargetPercentage)
      );
      dispatch({
        type: "UPDATE_STORY_PROGRESS",
        payload: {
          percentage: newPercentage,
        },
      });
    }
    // Scroll to bottom with a slight delay to ensure content is rendered
    setTimeout(() => {
      if (storyContainerRef.current) {
        storyContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 100);
  };

  const generateParagraphs = async () => {
    setIsGenerating(true);
    const context = getContextForMode(
      "new_paragraphs",
      state,
      0,
      state.generationMode,
      10
    );
    let instruction =
      "Write an interesting and attention grabbing setup for the story based on the premise. Introduce the main characters, settings and the initial conflict or plot.";
    if (isNsfw) {
      instruction =
        instruction +
        " The story should be erotic and contain explicit content. Describe the main characters and their physical appearance.";
    }
    try {
      const result = await getNewParagraphs(
        instruction,
        initialParagraphs,
        context,
        state.generationMode,
        onGenerateProgress,
        true,
        isNsfw
      );
      setActiveParagraph(null);
      await generateStoryTitle();

      // Open the continue content generator after paragraphs are generated
      setIsContinueStory(true);
    } catch (error) {
      console.error("Error generating paragraphs:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsGenerating(false);
    }
  };

  const generateStoryTitle = async () => {
    setIsGeneratingTitle(true);
    try {
      const context = {
        paragraphs: state.paragraphs.slice(0, 2).join(" "),
        premise: state.premise,
      };
      const generatedTitle = await generateTitle(context);
      console.log("generatedTitle", generatedTitle);
      if (!generatedTitle) {
        console.error("Error generating title:", error);
        return;
      }
      setTitle(generatedTitle);
      dispatch({ type: "SET_TITLE", payload: generatedTitle });
      dispatch({ type: "SET_NSFW", payload: isNsfw });
    } catch (error) {
      console.error("Error generating title:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsGeneratingTitle(false);
    }
  };

  const processOverallSummary = async (newParagraphs) => {
    let allParagraphs = [...state.paragraphs, ...newParagraphs];
    //slice last 5 paragraphs
    let lastParagraphs = allParagraphs.slice(-5);
    let overallSummary = await getSectionSummary(lastParagraphs, [
      state.overall_summary,
    ]);
    dispatch({ type: "SET_OVERALL_SUMMARY", payload: overallSummary });
  };

  const handleContentFinalize = (content, mode, context = null) => {
    if (mode === "new_paragraphs") {
      dispatch({ type: "ADD_PARAGRAPHS", payload: content });

      if (state.generationMode === "percentage_based") {
        let percentage_to_advance = state.storyProgress + 25; // Default or get from preferences
        if (percentage_to_advance > 100) {
          percentage_to_advance = 100;
        }
        dispatch({
          type: "UPDATE_STORY_PROGRESS",
          payload: { percentage: percentage_to_advance },
        });
      }
    } else if (mode === "insert" && context?.index != null) {
      // Insert paragraphs after the specified index
      const paragraphs = Array.isArray(content) ? content : [content];
      paragraphs.forEach((paragraph, i) => {
        dispatch({
          type: "INSERT_PARAGRAPH",
          payload: {
            index: context.index + i + 1,
            content: paragraph.content || paragraph,
          },
        });
      });
    } else if (mode === "rewrite" && context?.index != null) {
      // Replace the current paragraph
      dispatch({
        type: "UPDATE_PARAGRAPH",
        payload: {
          index: context.index,
          content: content[0]?.content || content[0] || content,
        },
      });
    }

    // Reset active paragraph after any operation
    setActiveParagraph(null);
    setIsExpanded(false);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const resetStory = () => {
    dispatch({ type: "RESET_STORY" });
    generateNewPremise();
    setActiveParagraph(null);
    setTitle("AI Story Generator");
    //reset the preferences
    localStorage.removeItem("storyGeneratorPreferences");
  };

  const handlePremiseUpdate = (newPremise, newGenre = "") => {
    dispatch({ type: "SET_PREMISE", payload: newPremise });
    dispatch({ type: "SET_GENRE", payload: newGenre });
    dispatch({ type: "SET_NSFW", payload: isNsfw });
  };

  const handleRemoveSectionBreak = (index) => {
    dispatch({
      type: "REMOVE_SECTION_BREAK",
      payload: { index },
    });
  };

  const toggleSectionCollapse = useCallback(
    (sectionIndex) => {
      dispatch({ type: "TOGGLE_SECTION_COLLAPSE", payload: sectionIndex });
    },
    [dispatch]
  );

  const isParagraphCollapsed = useCallback(
    (paragraphIndex) => {
      if (!state.collapsedSections) return false;
      const nextSectionBreak = state.paragraphs
        .slice(paragraphIndex)
        .findIndex((item) => item.type === "section_break");

      const nextSectionBreakIndex =
        nextSectionBreak !== -1 ? paragraphIndex + nextSectionBreak : -1;

      return state.collapsedSections.some(
        (sectionIndex) =>
          nextSectionBreakIndex !== -1 && nextSectionBreakIndex === sectionIndex
      );
    },
    [state.collapsedSections, state.paragraphs]
  );

  const toggleAllSections = useCallback(() => {
    dispatch({ type: "TOGGLE_ALL_SECTIONS" });
    scrollToTop();
  }, [dispatch]);

  const toggleSectionBreakButtons = useCallback(() => {
    setShowSectionBreakButtons((prev) => !prev);
  }, []);

  const toggleSummarySentencesButtons = useCallback(() => {
    setShowSummarySentencesButtons((prev) => !prev);
  }, []);

  const toggleContinueStory = useCallback(() => {
    scrollToBottom();
    setIsContinueStory((prev) => !prev);
  }, []);

  const scrollToBottom = () => {
    // Change the selector to target the paragraph elements directly
    const paragraphs = document.querySelectorAll(
      ".text-base.sm\\:text-lg.leading-relaxed[class*='text-']"
    );
    if (paragraphs.length > 0) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      lastParagraph.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      console.error("No paragraphs found");
    }
  };

  const scrollToTop = () => {
    const paragraphs = document.querySelectorAll(
      ".text-base.sm\\:text-lg.leading-relaxed.text-gray-800"
    );
    if (paragraphs.length > 0) {
      const firstParagraph = paragraphs[0];
      firstParagraph.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("No paragraphs found");
    }
  };

  const handleParagraphAction = useCallback(
    (action) => {
      if (activeParagraph !== null) {
        // Find the active paragraph component and call its handleModeChange function
        const paragraphComponent = document.querySelector(
          `[data-paragraph-index="${activeParagraph}"]`
        );
        if (paragraphComponent) {
          const handleModeChange =
            paragraphComponent.__react_internal_instance$.return.stateNode
              .handleModeChange;
          handleModeChange(action);
        }
      }
      if (action === null) {
        setActiveParagraph(null);
      }
    },
    [activeParagraph]
  );

  const handleDeleteParagraph = useCallback(
    (index) => {
      dispatch({ type: "DELETE_PARAGRAPH", payload: index });
      // Force a re-render of all paragraphs
      setActiveParagraph(null);
    },
    [dispatch]
  );

  const toggleGenerationMode = () => {
    const newMode =
      state.generationMode === "percentage_based"
        ? "open_ended"
        : "percentage_based";
    dispatch({ type: "SET_GENERATION_MODE", payload: newMode });
  };

  const getContentGeneratorTitle = (isPercentageBased) => {
    if (isPercentageBased) {
      return "Add custom instructions for to progress the story";
    }
    return "Add custom instructions for open ended story generation";
  };

  const onExpansion = (mode) => {
    // Navigate to compact version with current state
    let path = isNsfw
      ? "/ai-story-generator/nsfw/compact"
      : "/ai-story-generator/compact";
    // console.log("onExpansion", path, mode, activeParagraph);
    history.push(path, {
      fromFullVersion: true,
      story_title: state.title,
      mode,
      activeParagraph,
    });
  };

  const handleDraftStateChange = (index, isDrafting) => {
    // console.log({ index, isDrafting });
    setIsDrafting(isDrafting);
    if (isDrafting) {
      setDraftParagraphIndex(index);
    } else {
      setDraftParagraphIndex(null);
    }
  };

  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" content={helmetData.description} />
        <meta name="keywords" content={helmetData.keywords} />
        <link rel="canonical" href={helmetData.canonicalUrl} />
        <meta name="robots" content={helmetData.robots} />
        {isNsfw && <meta name="rating" content="mature" />}
        {isNsfw && (
          <meta name="age-verification" content="adult-content-warning" />
        )}
      </Helmet>
      <div
        className={`min-h-screen flex flex-col ${
          darkMode ? "bg-gray-900 text-gray-100" : "bg-gray-50"
        } ${isExpanded ? "overflow-hidden" : ""}`}
      >
        <header
          className={`sticky top-0 z-10 ${
            darkMode ? "bg-gray-800 text-gray-100" : "bg-white"
          } shadow-md transition-all duration-300 sm:py-1`}
        >
          <div className="container mx-auto px-4">
            <div className="hidden sm:block mb-4">
              <StoryActionButtons
                darkMode={darkMode}
                isNsfw={isNsfw}
                resetStory={resetStory}
              />
            </div>
            <div
              className={`flex flex-col sm:flex-row items-center justify-between transition-all duration-300 py-1`}
            >
              <StoryTitle
                title={title}
                isGeneratingTitle={isGeneratingTitle}
                onGenerateTitle={generateStoryTitle}
                isCompact={isScrolled}
                darkMode={darkMode}
              />
              {state.generationMode === "percentage_based" && (
                <div
                  className={`w-full sm:w-1/3 transition-all duration-300 ${
                    isScrolled ? "sm:ml-4" : ""
                  }`}
                >
                  <StoryProgressBar
                    isCompact={isScrolled}
                    darkMode={darkMode}
                  />
                </div>
              )}
            </div>
          </div>
        </header>

        <main
          className={`flex-grow container mx-auto px-4 py-2 ${
            isExpanded ? "hidden" : ""
          }`}
        >
          <div className="max-w-6xl mx-auto">
            <div className="mb-2">
              <StoryDetails
                premise={state.premise}
                genre={state.genre}
                onUpdate={handlePremiseUpdate}
                isFinal={state.paragraphs.length > 0}
                darkMode={darkMode}
                isNsfw={isNsfw}
              />

              {state.paragraphs.length === 0 && !isGenerating && (
                <div className="mt-6 flex flex-col sm:flex-row gap-4">
                  <div className="flex-1">
                    <div className="flex flex-col items-center gap-2">
                      <div className="relative inline-flex items-center p-1 rounded-full bg-gray-100">
                        <button
                          onClick={toggleGenerationMode}
                          className="relative flex items-center space-x-2"
                        >
                          <span
                            className={`absolute inset-0 w-1/2 h-full rounded-full transition-transform duration-300 ease-in-out transform ${
                              state.generationMode === "open_ended"
                                ? "translate-x-0 bg-blue-500"
                                : "translate-x-full bg-purple-500"
                            }`}
                          />
                          <span
                            className={`relative z-10 flex items-center justify-center w-24 h-8 text-sm font-medium transition-colors duration-300 ${
                              state.generationMode === "open_ended"
                                ? "text-white"
                                : "text-gray-500"
                            }`}
                          >
                            <BsInfinity className="mr-1" />
                            Open
                          </span>
                          <span
                            className={`relative z-10 flex items-center justify-center w-24 h-8 text-sm font-medium transition-colors duration-300 ${
                              state.generationMode === "percentage_based"
                                ? "text-white"
                                : "text-gray-500"
                            }`}
                          >
                            <FiTarget className="mr-1" />
                            Progress
                          </span>
                        </button>
                      </div>
                      <p
                        className={`text-sm ${
                          darkMode ? "text-white-300" : "text-gray-600"
                        } text-center`}
                      >
                        {state.generationMode === "open_ended"
                          ? "Free-flowing story without length constraints"
                          : "Structured story with progress tracking"}
                      </p>
                    </div>
                    <button
                      onClick={generateParagraphs}
                      className="mt-4 w-full bg-amber-500 hover:bg-amber-600 text-white font-bold py-3 px-4 rounded-lg text-sm transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-opacity-50 shadow-lg"
                    >
                      Generate Story
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`${
                darkMode ? "bg-gray-800" : "bg-white"
              } rounded-lg shadow-lg mb-8 transition-all duration-300`}
              ref={storyContainerRef}
            >
              {state.paragraphs.map((item, index) =>
                item.type === "section_break" ? (
                  <SectionBreak
                    key={`section-break-${index}`}
                    index={index}
                    onRemove={() => handleRemoveSectionBreak(index)}
                    summary={item.summary}
                    showSectionBreakButtons={showSectionBreakButtons}
                    isCollapsed={
                      state.collapsedSections
                        ? state.collapsedSections.includes(index)
                        : false
                    }
                    onToggleCollapse={() => toggleSectionCollapse(index)}
                  />
                ) : (
                  <Paragraph
                    key={`paragraph-${index}`}
                    content={item}
                    index={index}
                    isActive={activeParagraph === index}
                    setActiveParagraph={setActiveParagraph}
                    isCollapsed={isParagraphCollapsed(index)}
                    showSectionBreakButtons={showSectionBreakButtons}
                    showSummarySentencesButtons={showSummarySentencesButtons}
                    onDelete={handleDeleteParagraph}
                    darkMode={darkMode}
                    isNsfw={isNsfw}
                    isClickable={!isDrafting || draftParagraphIndex === index}
                    onDraftStateChange={(flag) =>
                      handleDraftStateChange(index, flag)
                    }
                  />
                )
              )}
              {isGenerating && (
                <div className="flex items-center justify-center py-8">
                  <FiLoader
                    className="animate-spin text-amber-500 mr-3"
                    size={28}
                  />
                  <p className="text-gray-700 text-lg">
                    Generating more content...
                  </p>
                </div>
              )}
              {state.paragraphs.length === 0 && !isGenerating && (
                <div className="flex items-center justify-center h-64 text-gray-500 text-xl">
                  Your generated story will appear here
                </div>
              )}
            </div>
          </div>
        </main>

        {state.paragraphs.length !== 0 && (
          <footer
            className={`sticky bottom-0 ${
              darkMode
                ? "bg-gray-800 border-gray-700"
                : "bg-white border-gray-200"
            } border-t mt-2`}
          >
            <div className="flex-grow container mx-auto px-4 py-2 max-w-8xl">
              <div className="hidden sm:block">
                {!isMobile() && (
                  <ContentGenerator
                    onFinalize={(content) =>
                      handleContentFinalize(content, "new_paragraphs", {
                        index: activeParagraph,
                      })
                    }
                    mode="new_paragraphs"
                    title={getContentGeneratorTitle(
                      state.generationMode === "percentage_based"
                    )}
                    onSaveInstruction={() => {}}
                    onExpanded={(mode) => onExpansion(mode)}
                    isExpanded={isExpanded}
                    generationMode={state.generationMode}
                    isNsfw={isNsfw}
                    onDraftStateChange={(flag) =>
                      handleDraftStateChange(null, flag)
                    }
                  />
                )}
              </div>
              {isContinueStory && activeParagraph === null && (
                <div className="sm:hidden mb-10">
                  <ContentGenerator
                    onFinalize={(content) =>
                      handleContentFinalize(content, "new_paragraphs", {
                        index: activeParagraph,
                      })
                    }
                    mode="new_paragraphs"
                    title={getContentGeneratorTitle(
                      state.generationMode === "percentage_based"
                    )}
                    onSaveInstruction={() => {}}
                    onExpanded={(mode) => onExpansion(mode)}
                    isExpanded={isExpanded}
                    generationMode={state.generationMode}
                    isNsfw={isNsfw}
                    onDraftStateChange={(flag) =>
                      handleDraftStateChange(null, flag)
                    }
                  />
                </div>
              )}
            </div>
          </footer>
        )}

        <div
          className={`sm:hidden fixed bottom-0 left-0 right-0 ${
            darkMode
              ? "bg-gray-800 border-gray-700"
              : "bg-white border-gray-200"
          } border-t p-1 shadow-lg`}
        >
          <StoryActionButtons
            darkMode={darkMode}
            isNsfw={isNsfw}
            activeParagraph={activeParagraph}
            resetStory={resetStory}
            toggleAllSections={toggleAllSections}
            areAllSectionsCollapsed={state.areAllSectionsCollapsed}
            toggleSectionBreakButtons={toggleSectionBreakButtons}
            showSectionBreakButtons={showSectionBreakButtons}
            showSummarySentencesButtons={showSummarySentencesButtons}
            toggleSummarySentencesButtons={toggleSummarySentencesButtons}
            isContinueStory={isContinueStory}
            toggleContinueStory={toggleContinueStory}
            onParagraphAction={handleParagraphAction}
            disabled={isDrafting}
          />
        </div>

        {showSummarySentencesButtons && (
          <PickedSentencesList
            pickedSentences={state.pickedSentences}
            paragraphs={state.paragraphs.map((p) =>
              typeof p === "string" ? p : p.content
            )}
            darkMode={darkMode}
            onSentenceClick={(paragraphIndex, sentenceIndex) => {
              // Scroll to the paragraph
              const paragraph = document.querySelector(
                `[data-paragraph-index="${paragraphIndex}"]`
              );
              if (paragraph) {
                paragraph.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default StoryGeneratorFull;
