import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  FiAlignLeft,
  FiList,
  FiRefreshCw,
  FiSettings,
  FiFeather,
  FiEdit,
  FiShare,
  FiX,
  FiMinimize2,
  FiMaximize2,
  FiPlusSquare,
  FiType,
  FiSave,
  FiGlobe,
} from "react-icons/fi";
import {
  FaEdit,
  FaSync,
  FaPlus,
  FaTrash,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { saveStoryToFile } from "../api/stories";
import { useStory } from "../context/StoryContext";
import ConfirmationModal from "./common/ConfirmationModal";

// Button configurations
const getButtonConfigs = ({
  darkMode,
  isNsfw,
  areAllSectionsCollapsed,
  showSectionBreakButtons,
  showSummarySentencesButtons,
  isContinueStory,
  resetStory,
  handlePublishStory,
}) => ({
  mainButtons: {
    stories: {
      Icon: FiList,
      label: "Stories",
      link: isNsfw ? "/stories/nsfw" : "/stories",
      className: `flex items-center justify-center transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
        darkMode
          ? "bg-gray-700 text-blue-400 hover:bg-gray-600 focus:ring-blue-400"
          : "bg-white text-blue-500 hover:bg-blue-50 focus:ring-blue-500"
      }`,
    },
    reset: {
      Icon: FiRefreshCw,
      label: "Reset",
      onClick: resetStory,
      className: `flex items-center justify-center transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
        darkMode
          ? "bg-gray-700 text-red-400 hover:bg-gray-600 focus:ring-red-400"
          : "bg-white text-red-500 hover:bg-red-50 focus:ring-red-500"
      }`,
    },
    publish: {
      Icon: FiGlobe,
      label: "Publish",
      onClick: handlePublishStory,
      className: `flex items-center justify-center transition-colors duration-300 ease-in-out rounded-full p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
        darkMode
          ? "bg-gray-700 text-green-400 hover:bg-gray-600 focus:ring-green-400"
          : "bg-white text-green-500 hover:bg-green-50 focus:ring-green-500"
      }`,
    },
  },
  sliderMenus: {
    Settings: {
      icon: FiSettings,
      options: [
        {
          icon: areAllSectionsCollapsed ? (
            <FiMaximize2 size={18} />
          ) : (
            <FiMinimize2 size={18} />
          ),
          action: "toggleAllSections",
          isActive: areAllSectionsCollapsed,
        },
        {
          icon: <FiPlusSquare size={18} />,
          action: "toggleSectionBreakButtons",
          isActive: showSectionBreakButtons,
        },
        {
          icon: <FiAlignLeft size={18} />,
          action: "toggleSummarySentencesButtons",
          isActive: showSummarySentencesButtons,
        },
      ],
    },
    Edit: {
      icon: FiEdit,
      options: [
        {
          icon: isContinueStory ? (
            <FiFeather size={18} />
          ) : (
            <FiFeather size={18} />
          ),
          action: "toggleContinueStory",
          isActive: isContinueStory,
          activeLabel: "Continue",
        },
      ],
    },
    Paragraph: {
      icon: FiType,
      options: [
        {
          icon: <FaSync size={16} />,
          action: "rewrite",
          activeLabel: "Rewrite",
        },
        {
          icon: <FaPlus size={16} />,
          action: "insert",
          activeLabel: "Insert",
        },
        {
          icon: <FaTrash size={16} />,
          action: "delete",
        },
      ],
    },
  },
});

const StoryActionButtons = ({
  activeParagraph,
  resetStory,
  toggleAllSections,
  areAllSectionsCollapsed,
  toggleSectionBreakButtons,
  toggleSummarySentencesButtons,
  showSectionBreakButtons,
  showSummarySentencesButtons,
  isContinueStory,
  toggleContinueStory,
  onParagraphAction,
  generationMode,
  toggleGenerationMode,
  darkMode = false,
  isNsfw = false,
  disabled = false,
}) => {
  const [openSlider, setOpenSlider] = useState(null);
  const [activeSliderOption, setActiveSliderOption] = useState(null);
  const [activeParagraphAction, setActiveParagraphAction] = useState(null);
  const { state } = useStory();
  const history = useHistory();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [publishFormData, setPublishFormData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (activeParagraph !== null && activeParagraph !== undefined) {
      setOpenSlider("Paragraph");
    } else {
      setOpenSlider(null);
    }
  }, [activeParagraph]);

  useEffect(() => {
    if (isContinueStory) {
      setOpenSlider("Edit");
      setActiveSliderOption("continue");
    }
  }, [isContinueStory]);

  // useEffect(() => {
  //   console.log({ disabled });
  // }, [disabled]);

  const toggleSlider = (slider) => {
    setOpenSlider(openSlider === slider ? null : slider);
  };

  const handlePublishStory = async () => {
    if (!state.paragraphs || state.paragraphs.length === 0) {
      alert("Please generate a story before publishing.");
      return;
    }

    const formSummary = {
      title: state.title,
      author: "Anonymous",
      html: `
        <div class="space-y-2">
          <div class="font-medium">Title: ${state.title}</div>
          <div>Author: Anonymous</div>
        </div>
      `,
    };

    setShowPublishModal(true);
    setPublishFormData(formSummary);
  };

  const handleEditForm = (updatedData) => {
    setPublishFormData({
      ...updatedData,
      html: `
        <div class="space-y-2">
          <div class="font-medium">Title: ${updatedData.title}</div>
          <div>Author: ${updatedData.author}</div>
        </div>
      `,
    });
  };

  const confirmPublish = async () => {
    try {
      const publishedStoryId = publishFormData.title
        .toLowerCase()
        .replace(/\s+/g, "_")
        .replace(/[^a-z0-9_]/g, "");

      const storyWithAuthor = {
        ...state,
        title: publishFormData.title,
        author: publishFormData.author,
      };

      const result = await saveStoryToFile(storyWithAuthor, publishedStoryId);
      setShowPublishModal(false);
      history.push(`/story/${publishedStoryId}`);
    } catch (error) {
      alert("Failed to publish story. Please try again.");
    }
  };

  const buttonConfigs = getButtonConfigs({
    darkMode,
    isNsfw,
    areAllSectionsCollapsed,
    showSectionBreakButtons,
    showSummarySentencesButtons,
    isContinueStory,
    resetStory,
    handlePublishStory,
  });

  const handleSliderAction = (action) => {
    const actionMap = {
      toggleAllSections,
      toggleSectionBreakButtons,
      toggleSummarySentencesButtons,
      toggleContinueStory,
      rewrite: () => {
        onParagraphAction("rewrite");
        setActiveParagraphAction("rewrite");
      },
      insert: () => {
        onParagraphAction("insert");
        setActiveParagraphAction("insert");
      },
      delete: () => {
        setShowDeleteModal(true);
        setActiveParagraphAction("delete");
      },
    };

    actionMap[action]?.();
    setActiveSliderOption(action);
  };

  const handleCloseSlider = (e) => {
    e.stopPropagation();
    setOpenSlider(null);
    onParagraphAction(null);
    setActiveParagraphAction(null);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setActiveParagraphAction(null);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    onParagraphAction("delete");
    setShowDeleteModal(false);
    setActiveParagraphAction(null);
    setOpenSlider(null);
    setIsDeleting(false);
  };

  const renderSliderContent = (options, sliderType) => (
    <div
      className={`flex space-x-1 overflow-x-auto w-[140px] transition-all duration-200 
        bg-opacity-95 backdrop-blur-sm rounded-full
        ${
          darkMode
            ? "bg-gray-800 border border-gray-700 shadow-lg"
            : "bg-white border border-gray-200 shadow-md"
        }
        ${disabled ? "opacity-50 pointer-events-none" : ""}`}
    >
      {options.map((option, index) => {
        const isActive =
          sliderType === "Paragraph"
            ? activeParagraphAction === option.action
            : option.isActive;

        const hasActiveOption =
          sliderType === "Paragraph"
            ? activeParagraphAction !== null
            : options.some((opt) => opt.isActive);

        return (
          <button
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleSliderAction(option.action);
            }}
            disabled={disabled}
            className={`py-1 px-1.5 rounded-full whitespace-nowrap transition-all duration-200 flex items-center text-xs 
              ${
                isActive
                  ? "bg-amber-500 text-white scale-100 min-w-[28px]"
                  : darkMode
                  ? "bg-gray-700/80 text-gray-300 hover:bg-gray-600"
                  : "bg-gray-100/80 text-gray-700 hover:bg-gray-200"
              } 
              ${hasActiveOption && !isActive ? "scale-75" : ""}
              ${isActive && option.activeLabel ? "w-[64px]" : "w-[28px]"}
            `}
          >
            <span className="flex items-center justify-center w-full">
              {option.icon}
              {isActive && option.activeLabel && (
                <span className="ml-1 truncate max-w-[36px]">
                  {option.activeLabel}
                </span>
              )}
            </span>
          </button>
        );
      })}
    </div>
  );

  const renderMainButtons = () => {
    // Separate Settings and Edit from Paragraph
    const mainMenus = ["Settings", "Edit"];
    const paragraphMenu = "Paragraph";

    return (
      <div
        className={`flex items-center space-x-1 transition-all duration-300 ${
          openSlider ? "-translate-x-16" : ""
        }`}
      >
        {/* Regular menus (Settings and Edit) */}
        {mainMenus.map((slider) => (
          <div
            key={slider}
            className={`transition-all duration-300 relative ${
              openSlider && openSlider !== slider
                ? "opacity-0 w-0 invisible pointer-events-none"
                : "opacity-100"
            }`}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleSlider(slider);
              }}
              className={`flex items-center justify-center transition-all duration-300 ease-in-out p-1 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 rounded-full ${
                openSlider === slider
                  ? darkMode
                    ? "bg-gray-800 text-amber-400 w-8 h-8 ring-2 ring-amber-400 relative z-10"
                    : "bg-white text-amber-500 w-8 h-8 ring-2 ring-amber-500 relative z-10"
                  : darkMode
                  ? "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-gray-500 w-6 h-6"
                  : "bg-white text-gray-600 hover:bg-gray-50 focus:ring-gray-300 w-6 h-6"
              }`}
              aria-label={slider}
            >
              {React.createElement(buttonConfigs.sliderMenus[slider].icon, {
                size: openSlider === slider ? 20 : 18,
              })}
            </button>

            {/* Sliding options menu */}
            {openSlider === slider && (
              <div className="absolute left-full top-1/2 -translate-y-1/2 flex items-center space-x-1 bg-opacity-90 rounded-full py-1 px-2">
                <div className="flex items-center space-x-1 origin-left animate-slide-from-button">
                  {renderSliderContent(
                    buttonConfigs.sliderMenus[slider].options,
                    slider
                  )}
                  <button
                    onClick={handleCloseSlider}
                    disabled={disabled}
                    className={`rounded-full p-1 shadow-md focus:outline-none w-6 h-6 flex items-center justify-center ${
                      darkMode
                        ? "bg-red-900 text-red-100"
                        : "bg-red-200 text-gray-600"
                    }`}
                    aria-label="Close"
                  >
                    <FiX size={14} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}

        {/* Paragraph menu - only show when a paragraph is active */}
        {activeParagraph !== null && (
          <div
            className={`transition-all duration-300 relative ${
              openSlider && openSlider !== paragraphMenu
                ? "opacity-0 w-0 invisible pointer-events-none"
                : "opacity-100"
            }`}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleSlider(paragraphMenu);
              }}
              className={`flex items-center justify-center transition-all duration-300 ease-in-out p-1 shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 rounded-full ${
                openSlider === paragraphMenu
                  ? darkMode
                    ? "bg-gray-800 text-amber-400 w-8 h-8 ring-2 ring-amber-400 relative z-10"
                    : "bg-white text-amber-500 w-8 h-8 ring-2 ring-amber-500 relative z-10"
                  : darkMode
                  ? "bg-amber-600 text-white hover:bg-amber-700 focus:ring-amber-400 w-6 h-6"
                  : "bg-amber-500 text-white hover:bg-amber-600 focus:ring-amber-300 w-6 h-6"
              }`}
              aria-label={paragraphMenu}
            >
              {React.createElement(
                buttonConfigs.sliderMenus[paragraphMenu].icon,
                {
                  size: openSlider === paragraphMenu ? 18 : 18,
                }
              )}
            </button>

            {/* Sliding options menu */}
            {openSlider === paragraphMenu && (
              <div className="absolute left-full top-1/2 -translate-y-1/2 flex items-center space-x-1 bg-opacity-90 rounded-full py-1 px-2">
                <div className="flex items-center space-x-1 origin-left animate-slide-from-button">
                  {renderSliderContent(
                    buttonConfigs.sliderMenus[paragraphMenu].options,
                    paragraphMenu
                  )}
                  <button
                    onClick={handleCloseSlider}
                    disabled={disabled}
                    className={`rounded-full p-1 shadow-md focus:outline-none w-6 h-6 flex items-center justify-center ${
                      darkMode
                        ? "bg-red-900 text-red-100"
                        : "bg-red-200 text-gray-600"
                    }`}
                    aria-label="Close"
                  >
                    <FiX size={14} />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative" onClick={(e) => e.stopPropagation()}>
      <ConfirmationModal
        isOpen={showPublishModal}
        onClose={() => setShowPublishModal(false)}
        onConfirm={confirmPublish}
        title="Publish Story"
        formContent={publishFormData}
        onEditForm={handleEditForm}
        darkMode={darkMode}
      />
      <ConfirmationModal
        isOpen={showDeleteModal}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title="Delete Paragraph"
        message="Are you sure you want to delete this paragraph? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        darkMode={darkMode}
        isDestructive={true}
      />
      <div className="flex items-center justify-between w-full space-x-2">
        <div className="flex items-center space-x-2">
          <Link
            to={buttonConfigs.mainButtons.stories.link}
            className={buttonConfigs.mainButtons.stories.className}
            aria-label="View Stories"
          >
            <buttonConfigs.mainButtons.stories.Icon size={18} />
            <span className="ml-1 text-xs hidden sm:inline">
              {buttonConfigs.mainButtons.stories.label}
            </span>
          </Link>
        </div>

        <div className="sm:hidden flex-grow">
          <div className="relative flex justify-center">
            {renderMainButtons()}
          </div>
        </div>

        <div className="flex items-center space-x-2">
          {Object.entries(buttonConfigs.mainButtons)
            .filter(([key]) => key !== "stories")
            .map(([key, config]) => (
              <button
                key={key}
                onClick={(e) => {
                  e.stopPropagation();
                  config.onClick();
                }}
                className={`${config.className} ${
                  disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={disabled}
                aria-label={config.label}
              >
                <config.Icon size={18} />
                <span className="ml-1 text-xs hidden sm:inline">
                  {config.label}
                </span>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StoryActionButtons;
