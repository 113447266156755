import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import Tabs from "../components/Tabs";
import StoryDashboard from "../components/StoryDashboard";
import StoryList from "../components/StoryList";
import { getPublishedStories } from "../api/stories";
import {
  FiPlusCircle,
  FiSave,
  FiTrash2,
  FiArrowRightCircle,
} from "react-icons/fi";
import {
  loadAllStoriesFromLocalStorage,
  clearAllStoriesFromLocalStorage,
  deleteStoryFromLocalStorage,
} from "../utils/storageUtils";
import { useStory } from "../context/StoryContext";
import { getStoriesPageHelmet } from "../utils/helmetUtils";
import { useAuth } from "../context/AuthContext";
import { saveStoryToAccount } from "../api/stories";
import { getUserStories } from "../api/stories";

const StoriesPage = ({ darkMode = false, isNsfw = false }) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("public");
  const [publicStories, setPublicStories] = useState([]);
  const [privateStories, setPrivateStories] = useState({
    published: [],
    unpublished: [],
    saved: [],
  });
  const [error, setError] = useState(null);
  const { dispatch } = useStory();
  const { user } = useAuth();
  const [savingStates, setSavingStates] = useState({});
  const [savedStoryIds, setSavedStoryIds] = useState(new Set());

  useEffect(() => {
    loadStories();
  }, [activeTab]);

  useEffect(() => {
    setPrivateStories((prev) => ({
      ...prev,
      saved: [],
    }));
    loadSavedStories();
    loadStories();
  }, [isNsfw]);

  useEffect(() => {
    loadSavedStories();
    loadStories();
  }, [user]);

  const loadSavedStories = async () => {
    if (!user) {
      setSavedStoryIds(new Set());
      return;
    }

    try {
      const stories = await getUserStories(isNsfw);
      setPrivateStories((prev) => ({
        ...prev,
        saved: stories,
      }));
      const savedIds = new Set(stories.map((story) => story.formatted_id));
      console.log("savedIds", savedIds);
      setSavedStoryIds(savedIds);
    } catch (error) {
      console.error("Failed to load saved stories:", error);
    }
  };

  const loadStories = () => {
    setError(null);
    if (activeTab === "public") {
      loadPublicStories();
    } else {
      loadPrivateStories();
    }
  };

  const loadPublicStories = async () => {
    try {
      const response = await getPublishedStories(isNsfw);
      setPublicStories(response.stories);
    } catch (error) {
      console.error("Failed to load public stories:", error);
      setError("Failed to load public stories. Please try again.");
    }
  };

  const loadPrivateStories = () => {
    console.log("load private stories");
    try {
      const cachedStories = loadAllStoriesFromLocalStorage(isNsfw);
      const unpublishedStories = cachedStories.filter(
        (story) => !story.isPublished
      );

      setPrivateStories((prev) => ({
        ...prev,
        published: cachedStories.filter((story) => story.isPublished),
        unpublished: unpublishedStories,
      }));
    } catch (error) {
      console.error("Failed to load private stories from cache:", error);
      setError("Failed to load private stories. Please try again.");
    }
  };

  const handleClearAll = () => {
    if (
      window.confirm("Are you sure you want to clear all unpublished stories?")
    ) {
      clearAllStoriesFromLocalStorage();
      dispatch({ type: "RESET_STORY" });
      loadPrivateStories();
    }
  };

  const handleDeleteStory = (storyId) => {
    if (window.confirm("Are you sure you want to delete this story?")) {
      deleteStoryFromLocalStorage(storyId);
      loadPrivateStories();
    }
  };

  const handleSaveStory = async (story) => {
    if (!user) return;

    setSavingStates((prev) => ({
      ...prev,
      [story.id]: "saving",
    }));

    try {
      await saveStoryToAccount(story);
      setSavingStates((prev) => ({
        ...prev,
        [story.id]: "saved",
      }));

      setSavedStoryIds((prev) => new Set([...prev, story.id]));

      setTimeout(() => {
        setSavingStates((prev) => ({
          ...prev,
          [story.id]: null,
        }));
      }, 2000);
    } catch (error) {
      console.error("Failed to save story:", error);
      setSavingStates((prev) => ({
        ...prev,
        [story.id]: "error",
      }));
    }
  };

  const handleEditStory = (storyId, storyTitle, from_saved = false) => {
    const path = isNsfw ? "/ai-story-generator/nsfw" : "/ai-story-generator";
    // console.log("storyId", storyId);

    history.push(path, {
      story_id: storyId,
      story_title: storyTitle,
      from_saved: from_saved,
    });
  };

  const StoryListItem = ({ story, onDelete, onEdit }) => {
    const savingState = savingStates[story.id];
    const isStorySaved = savedStoryIds.has(story.id);
    const isInSavedSection = privateStories.saved.some(
      (s) => s.formatted_id === story.formatted_id || s.id === story.id
    );

    return (
      <div
        className={`flex items-center justify-between p-4 mb-2 rounded-lg ${
          darkMode ? "bg-gray-800" : "bg-white"
        } shadow`}
      >
        <div className="flex-grow">
          <span
            className={`text-lg font-semibold ${
              darkMode ? "text-gray-100" : "text-gray-800"
            } hover:text-blue-500`}
          >
            {story.title}
          </span>
        </div>

        <div className="flex items-center">
          {user && !isInSavedSection && (
            <div className="flex items-center">
              {savingState === "saving" && (
                <div className="text-blue-500 text-sm animate-pulse">
                  Saving...
                </div>
              )}
              {/* {(savingState === "saved" || isStorySaved) && (
                <div
                  className="text-green-500 text-sm"
                  title="Saved to account"
                >
                  ✓
                </div>
              )} */}
              {savingState === "error" && (
                <div className="flex items-center gap-1">
                  <span
                    className="text-red-500"
                    title="Failed to save. Click save to retry."
                  >
                    ⚠️
                  </span>
                  <button
                    onClick={() => handleSaveStory(story)}
                    className="text-blue-500 hover:text-blue-600 px-2 py-1 rounded"
                  >
                    <FiSave />
                  </button>
                </div>
              )}
              {!savingState && (
                <button
                  onClick={() => handleSaveStory(story)}
                  className="text-blue-500 hover:text-blue-600 px-2 py-1 rounded"
                  title="Save to account"
                >
                  <FiSave />
                </button>
              )}
            </div>
          )}

          <button
            onClick={() => onDelete(story.id)}
            className="text-red-500 hover:text-red-600 p-1 rounded"
          >
            <FiTrash2 />
          </button>
          <button
            onClick={() =>
              onEdit(
                story.id ? story.id : story.formatted_id,
                story.title,
                story.id ? false : true
              )
            }
            className="text-yellow-500 hover:text-yellow-600 p-1 rounded"
          >
            <FiArrowRightCircle />
          </button>
        </div>
      </div>
    );
  };

  const StoryList = ({ stories, onDelete }) => (
    <div className="space-y-2">
      {stories.map((story) => (
        <StoryListItem
          key={story.id}
          story={story}
          onDelete={onDelete}
          onEdit={handleEditStory}
        />
      ))}
    </div>
  );

  const tabs = [
    { id: "public", label: "Discover Stories" },
    { id: "private", label: "My Stories" },
  ];

  const NoStoriesMessage = ({ message }) => (
    <div className="text-gray-500 text-center py-4">{message}</div>
  );

  const helmetData = getStoriesPageHelmet();

  const containerClass = `flex-grow overflow-y-auto h-full flex flex-col ${
    darkMode ? "bg-gray-900" : "bg-gray-100"
  }`;

  const titleClass = `text-2xl font-bold ${
    darkMode ? "text-gray-100" : "text-gray-800"
  }`;

  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" content={helmetData.description} />
        <meta name="keywords" content={helmetData.keywords} />
        <link rel="canonical" href={helmetData.canonicalUrl} />
        <meta name="robots" content={helmetData.robots} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={helmetData.canonicalUrl} />
        <meta property="og:title" content={helmetData.title} />
        <meta property="og:description" content={helmetData.description} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={helmetData.canonicalUrl} />
        <meta property="twitter:title" content={helmetData.title} />
        <meta property="twitter:description" content={helmetData.description} />
      </Helmet>
      <div className={containerClass}>
        <div className="flex justify-between items-center p-4">
          <h1 className={titleClass}>{isNsfw ? "Adult Stories" : "Stories"}</h1>
          <Link
            to={
              isNsfw
                ? "/ai-story-generator/nsfw?new=true"
                : "/ai-story-generator?new=true"
            }
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <FiPlusCircle className="mr-2" />
            Generate New Story
          </Link>
        </div>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-4 mb-4">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={loadStories}
            >
              <span className="text-red-500 hover:text-red-800">Retry</span>
            </button>
          </div>
        )}

        {activeTab === "public" ? (
          <StoryDashboard stories={publicStories} darkMode={darkMode} />
        ) : (
          <div className="p-4">
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-green-600 mb-2">
                Saved to Account
              </h2>
              {!user ? (
                <div className="text-center py-4 bg-blue-50 rounded-lg">
                  <p className="text-gray-700 mb-2">
                    Please log in to save and view your stories
                  </p>
                  <Link
                    to="/login"
                    className="text-blue-500 hover:text-blue-700 font-medium"
                  >
                    Login here
                  </Link>
                </div>
              ) : savedStoryIds.size > 0 ? (
                <StoryList
                  stories={privateStories.saved}
                  type="saved"
                  onDelete={handleDeleteStory}
                />
              ) : (
                <NoStoriesMessage message="No stories saved to your account yet." />
              )}
            </div>

            <div>
              <div className="flex justify-between items-center mt-6 mb-2">
                <div>
                  <h2 className="text-xl font-semibold text-purple-600">
                    Unpublished (Local Cache)
                  </h2>
                  <p className="text-sm text-gray-500 mt-1">
                    These stories are stored in your browser and will be lost if
                    you clear your cache
                  </p>
                </div>
                {privateStories.unpublished.length > 0 && (
                  <button
                    onClick={handleClearAll}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                  >
                    <FiTrash2 className="mr-2" />
                    Clear All
                  </button>
                )}
              </div>
              {privateStories.unpublished.length > 0 ? (
                <StoryList
                  stories={privateStories.unpublished}
                  type="private"
                  onDelete={handleDeleteStory}
                />
              ) : (
                <NoStoriesMessage message="No unpublished stories in local cache." />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StoriesPage;
