import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkTokenExpiry = () => {
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    if (tokenExpiry) {
      const isExpired = new Date().getTime() > parseInt(tokenExpiry);
      if (isExpired) {
        logout();
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    // Check if user is logged in on mount
    const token = localStorage.getItem("token");
    const userEmail = localStorage.getItem("userEmail");

    if (token && userEmail && !checkTokenExpiry()) {
      setUser({ email: userEmail });
    } else {
      logout();
    }
    setLoading(false);

    // Set up periodic token check
    const tokenCheckInterval = setInterval(checkTokenExpiry, 60000); // Check every minute

    return () => clearInterval(tokenCheckInterval);
  }, []);

  const login = (token, email) => {
    // Set token expiry to 24 hours from now
    const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
    localStorage.setItem("token", token);
    localStorage.setItem("userEmail", email);
    localStorage.setItem("tokenExpiry", expiryTime.toString());
    setUser({ email });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("tokenExpiry");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
