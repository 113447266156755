import React, { useState, useEffect } from "react";
import {
  FiX,
  FiList,
  FiChevronDown,
  FiChevronUp,
  FiCornerUpRight,
} from "react-icons/fi";
import { splitIntoParagraphAndSentences } from "../utils/paragraphUtils";

const PickedSentencesList = ({
  pickedSentences,
  paragraphs,
  darkMode,
  onSentenceClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [collapsedGroups, setCollapsedGroups] = useState({});

  // Add useEffect to handle body scrolling
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const toggleGroup = (paragraphIndex) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [paragraphIndex]: !prev[paragraphIndex],
    }));
  };

  // Get all picked sentences grouped by paragraph
  const getGroupedPickedSentences = () => {
    const groupedSentences = {};

    Object.entries(pickedSentences).forEach(
      ([paragraphIndex, sentenceIndices]) => {
        const paragraph = paragraphs[paragraphIndex];
        if (paragraph && !paragraph.type) {
          // Skip section breaks
          const paragraphSentences = splitIntoParagraphAndSentences(paragraph);
          const sentences = sentenceIndices
            .map((sentenceIndex) => {
              if (paragraphSentences[sentenceIndex]) {
                return {
                  paragraphIndex: parseInt(paragraphIndex),
                  sentenceIndex: parseInt(sentenceIndex),
                  text: paragraphSentences[sentenceIndex],
                };
              }
              return null;
            })
            .filter(Boolean);

          if (sentences.length > 0) {
            groupedSentences[paragraphIndex] = sentences;
          }
        }
      }
    );
    return groupedSentences;
  };

  const groupedSentences = getGroupedPickedSentences();
  const hasPickedSentences = Object.keys(groupedSentences).length > 0;

  const handleJumpToSentence = (paragraphIndex, sentenceIndex) => {
    onSentenceClick(paragraphIndex, sentenceIndex);
    setIsOpen(false);
  };

  return (
    <>
      {/* Fixed button */}
      <button
        onClick={toggleList}
        className={`fixed bottom-20 right-4 z-50 p-3 rounded-full shadow-lg ${
          darkMode
            ? "bg-gray-700 hover:bg-gray-600 text-white"
            : "bg-white hover:bg-gray-100 text-gray-800"
        } transition-all duration-300`}
      >
        <FiList size={24} />
      </button>

      {/* Sliding panel */}
      <div
        className={`fixed right-0 top-0 h-full w-80 ${
          darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        } shadow-lg transform transition-transform duration-300 z-50 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Picked Sentences</h3>
            <button
              onClick={toggleList}
              className={`p-2 rounded-full ${
                darkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
            >
              <FiX size={20} />
            </button>
          </div>

          <div className="space-y-4 overflow-y-auto max-h-[calc(100vh-6rem)] pb-20">
            {hasPickedSentences ? (
              Object.entries(groupedSentences).map(
                ([paragraphIndex, sentences]) => (
                  <div
                    key={`paragraph-${paragraphIndex}`}
                    className={`border rounded-lg ${
                      darkMode
                        ? "border-gray-700 bg-gray-750"
                        : "border-gray-200 bg-gray-50"
                    }`}
                  >
                    <button
                      onClick={() => toggleGroup(paragraphIndex)}
                      className={`w-full px-4 py-3 flex justify-between items-center ${
                        darkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
                      } rounded-t-lg transition-colors duration-200`}
                    >
                      <span className="font-medium">
                        Paragraph {parseInt(paragraphIndex) + 1}
                      </span>
                      {collapsedGroups[paragraphIndex] ? (
                        <FiChevronDown size={20} />
                      ) : (
                        <FiChevronUp size={20} />
                      )}
                    </button>
                    {!collapsedGroups[paragraphIndex] && (
                      <div className="px-4 pb-3 space-y-2">
                        {sentences.map((sentence) => (
                          <div
                            key={`${sentence.paragraphIndex}-${sentence.sentenceIndex}`}
                            className={`p-2 rounded group ${
                              darkMode
                                ? "hover:bg-gray-700/50"
                                : "hover:bg-gray-100"
                            } transition-colors duration-200`}
                          >
                            <div className="flex items-start gap-2">
                              <div className="text-sm flex-grow">
                                {sentence.text}
                              </div>
                              <button
                                onClick={() =>
                                  handleJumpToSentence(
                                    sentence.paragraphIndex,
                                    sentence.sentenceIndex
                                  )
                                }
                                className={`p-1.5 rounded-full shrink-0 mt-0.5 ${
                                  darkMode
                                    ? "hover:bg-gray-600 text-gray-300 hover:text-white"
                                    : "hover:bg-gray-200 text-gray-500 hover:text-gray-700"
                                } opacity-0 group-hover:opacity-100 transition-all duration-200`}
                                title="Jump to sentence"
                              >
                                <FiCornerUpRight size={16} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )
              )
            ) : (
              <div className="text-center text-gray-500 py-8">
                No sentences picked yet
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PickedSentencesList;
