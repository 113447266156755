import { splitIntoParagraphAndSentences } from "./paragraphUtils";

export const getContextForMode = (
  mode,
  state,
  index,
  generationMode = "open_ended",
  percentage_to_advance = 25
) => {
  switch (mode) {
    case "rewrite":
      return getRewriteContext(state, index);
    case "insert":
      return getInsertContext(state, index);
    case "new_paragraphs":
      return getNewParagraphsContext(
        state,
        generationMode,
        percentage_to_advance
      );
    case "new_paragraphs_suggestions":
      return getNewParagraphsSuggestionContext(state, percentage_to_advance);
    case "continue_scene":
      return getContinueSceneContext(state, index);
    default:
      return {};
  }
};

const getRewriteContext = (state, index) => {
  let next_paragraph = "";
  if (index + 1 < state.paragraphs.length) {
    next_paragraph = state.paragraphs[index + 1];
    if (next_paragraph.type === "section_break") {
      next_paragraph = "";
    }
  }
  let previous_paragraphs = state.paragraphs
    .slice(0, index + 1)
    .filter((item) => !item.type);
  let previous_paragraphs_picked = previous_paragraphs.slice(-1).join("\n\n");

  const pickedSentencesText = getPickedSentencesFromParagraphs(
    previous_paragraphs,
    state.pickedSentences
  );
  return {
    paragraph: state.paragraphs[index],
    premise: state.premise,
    previous_paragraph: previous_paragraphs_picked,
    next_paragraph: next_paragraph,
    picked_sentences: pickedSentencesText || "",
  };
};

const getPickedSentencesFromParagraphs = (paragraphs, pickedSentencesMap) => {
  let pickedSentencesText = [];
  if (!pickedSentencesMap) return pickedSentencesText;

  Object.entries(pickedSentencesMap).forEach(
    ([paragraphIndex, sentenceIndices]) => {
      const paragraph = paragraphs[parseInt(paragraphIndex)];
      if (!paragraph || paragraph.type === "section_break") return;

      // Split paragraph into sentences (basic split - you may want to use a more robust method)
      const sentences = splitIntoParagraphAndSentences(paragraph);

      sentenceIndices.forEach((sentenceIndex) => {
        if (sentences[sentenceIndex]) {
          pickedSentencesText.push(sentences[sentenceIndex]);
        }
      });
    }
  );

  return pickedSentencesText.join(" ");
};

const getInsertContext = (state, index) => {
  let next_paragraph = "";
  if (index + 1 < state.paragraphs.length) {
    next_paragraph = state.paragraphs[index + 1];
    if (next_paragraph.type === "section_break") {
      next_paragraph = "";
    }
  }
  let previous_paragraphs = state.paragraphs
    .slice(0, index + 1)
    .filter((item) => !item.type);
  let previous_paragraphs_picked = previous_paragraphs.slice(-3).join("\n\n");

  let prev_sections = state.paragraphs
    .slice(0, index + 1)
    .filter((item) => item.type === "section_break")
    .map((item) => item.summary);
  prev_sections = prev_sections.join("\n\n");

  // Add picked sentences to context
  const pickedSentencesText = getPickedSentencesFromParagraphs(
    previous_paragraphs,
    state.pickedSentences
  );

  // console.log(pickedSentencesText);

  return {
    premise: state.premise,
    previous_paragraphs: previous_paragraphs_picked,
    next_paragraph: next_paragraph,
    previous_sections: prev_sections,
    picked_sentences: pickedSentencesText || "",
  };
};

const getNewParagraphsContext = (
  state,
  generationMode = "open_ended",
  percentage_to_advance = 25
) => {
  const paragraphs = state.paragraphs.filter(
    (item) => item.type !== "section_break"
  );
  const previous_paragraphs = paragraphs.slice(-3).join("\n\n");
  let prev_sections = state.paragraphs
    .filter((item) => item.type === "section_break")
    .map((item) => item.summary);
  prev_sections = prev_sections.join("\n\n");
  let storyProgress = state.storyProgress || 0;
  if (storyProgress + percentage_to_advance > 100) {
    percentage_to_advance = 100 - storyProgress;
  }

  // Add picked sentences to context
  const pickedSentencesText = getPickedSentencesFromParagraphs(
    state.paragraphs,
    state.pickedSentences
  );

  if (generationMode === "percentage_based") {
    return {
      premise: state.premise,
      genre: state.genre,
      previous_paragraphs: previous_paragraphs,
      previous_sections: prev_sections,
      current_percentage: storyProgress || 0,
      target_percentage: storyProgress + percentage_to_advance,
      percentage_to_advance: percentage_to_advance,
      overall_summary: state.overall_summary,
      picked_sentences: pickedSentencesText || "",
    };
  } else if (
    generationMode === "open_ended" ||
    generationMode === "open_ended_dialogue"
  ) {
    return {
      premise: state.premise,
      genre: state.genre,
      previous_paragraphs: previous_paragraphs,
      previous_sections: prev_sections,
      picked_sentences: pickedSentencesText || "",
    };
  }
};

const getNewParagraphsSuggestionContext = (state, percentage_to_advance) => {
  const paragraphs = state.paragraphs.filter(
    (item) => item.type !== "section_break"
  );
  const previous_paragraphs = paragraphs.slice(-3).join("\n\n");
  return {
    premise: state.premise,
    genre: state.genre,
    previous_paragraphs: previous_paragraphs,
  };
};

const getContinueSceneContext = (state, index) => {
  return {
    premise: state.premise,
    previous_scene_elements: state.elements,
  };
};
